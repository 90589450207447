

.home-page {
    padding: 155px 45px;
}
/*************************** Begin Filter Section ***************************/
.home-page .filter-section {
    background: #fff;
    border-radius: 37px;
    padding: 15px 25px;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

.home-page .filter-section .search-by-id-section,
.home-page .filter-section .search-advanced-section {
    margin: 20px 0;
}

.home-page .filter-section .search-by-id-section .form,
.home-page .filter-section .search-advanced-section {
    /* border: 1px solid #DCDFE6; */
    border: 1px solid #c6cbd5;
    padding: 10px 15px;
    border-radius: 20px;
}

.home-page .filter-section label {
    color: #777F8D;
    margin-bottom: 4px;
}

.home-page .filter-section input,
.home-page .filter-section select {
    border-radius: 20px;
    border: 1px solid #DCDFE6;
    padding: 5px 15px;
}

.home-page .filter-section select {
    margin-left: 5px;
}

.home-page .filter-section input:focus,
.home-page .filter-section select:focus {
    box-shadow: none;
    border: 1px solid #6FCFF5;
}

.home-page .filter-section input::placeholder {
    color: #A2A8B4;
}

.home-page .filter-section span.sub-title {
    color: #262626;
    font-weight: 500;
}

.home-page .filter-section span.btn {
    background: linear-gradient(90deg, #67988E, #184438);
    /* box-shadow: 0 4px 15px 0 #ff7b61; */
    color: #fff;
    border-radius: 20px;
    padding: 4px 17px;
}

.absolute-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(-20px);
}

.position-relative {
    position: relative;
}

/*************************** End Filter Section ***************************/
/*************************** Begin Taches Section ***************************/
.home-page .taches-section {
    background: #fff;
    border-radius: 40px;
    padding: 20px 13px;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

.home-page .taches-section h2 {
    padding-left: 10px;
}

.home-page .taches-section ul {
    margin: 0;
    padding: 0;
}

.home-page .taches-section ul li {
    list-style: none;
    margin: 5px 0;
    padding: 10px 0 10px 20px;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
}

.home-page .taches-section ul li .no-color {
    display: block;
}

.home-page .taches-section ul li .with-color {
    display: none;
    position: absolute;
    top: 10px;
    left: 19px;
    color: #fff;
    ;
}

.home-page .taches-section ul li div span {
    margin-left: 6px;
}

.home-page .taches-section ul li.tache-active {
    /* background: linear-gradient(90deg, #ff61ab, #ff6161, #ff6392); */
    background: linear-gradient(90deg, #67988E, #184438);
}

.home-page .taches-section ul li.tache-active .no-color {
    opacity: 0;
}

.home-page .taches-section ul li.tache-active .with-color {
    display: block;
}

.home-page .taches-section svg {
    vertical-align: middle;
}
/*************************** End Taches Section ***************************/

/*************************** Begin commands Section ***************************/
.home-page .commands-section {
    background: #fff;
    padding: 20px 20px;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    overflow-x: auto;
    min-height: 582px;
    width: 100%;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: middle;
    background: transparent;
}

.home-page .commands-section h2 {
    margin-bottom: 13px;
    padding-left: 15px;
}
.home-page .commands-section .no-data-wrapper{
    text-align: center;
    color: #184438;
    font-weight: 600;
    font-size: 30px;
    margin-top: 100px;
}
.home-page .commands-section .table {
    width: 100%;
    display: block;
    white-space: nowrap;
    margin-bottom: 0;
}

.home-page .commands-section .table thead {
    position: relative;
    height: 40px;
    line-height: 40px;
}

.home-page .commands-section .table tbody {
    border-top: none;
}

.home-page .commands-section .table tr {
    position: relative;
    border-bottom: 1px solid #dddddd;
}

.home-page .commands-section .table tr td:nth-child(5),
.home-page .commands-section .table tr th:nth-child(5),
.home-page .commands-section .table tr td:nth-child(7),
.home-page .commands-section .table tr th:nth-child(7),
.home-page .commands-section .table tr td:nth-child(10),
.home-page .commands-section .table tr th:nth-child(10) {
    display: none;
}

.home-page .commands-section .table tbody tr td,
.home-page .commands-section .table thead tr th {
    font-size: 15px;
    text-align: center;
}
.home-page .commands-section .table tbody tr td.etat span {
    color: #fff;
    border-radius: 21px;
    padding: 7px 10px;
}

 /******* Begin Etat Colors *******/
.home-page .commands-section .table tbody tr td.etat span.non-traite-color {
    background: #6FCFF5;
}

.home-page .commands-section .table tbody tr td.etat span.refuse-color {
    background: #FE2400;
}

.home-page .commands-section .table tbody tr td.etat span.accepte-color {
    background: #AED581;
}

.home-page .commands-section .table tbody tr td.etat span.approuve-color {
    background: #107E39;
}

.home-page .commands-section .table tbody tr td.etat span.annule_par_client {
    background: #DAA801;
}

.home-page .commands-section .table tbody tr td.etat span.accuse {
    background: #2BF5BD;
}

.home-page .commands-section .table tbody tr td.etat span.financable-color {
    background: #d581cb;
}

.home-page .commands-section .table tbody tr td.etat span.non-financable-color {
    background: #7d828d;
}

.home-page .commands-section .table tbody tr td.etat span.controler-color {
    background: #262626;
}
 /******* End Etat Colors *******/





.home-page .commands-section .table tbody tr td:last-child,
.home-page .commands-section .table thead tr th:last-child {
    text-align: right;
    width: 100% !important;
    cursor: pointer;
}
.home-page .commands-section .table div#rect-div {
  width: 20px;
  height: 20px;
  border: 1px solid #262626;
  display: inline-block;
}
.home-page .commands-section .table tbody tr td svg{
    vertical-align: middle;
}
/*************************** End commands Section ***************************/

/******** Begin scrollbar css ********/
.home-page .commands-section::-webkit-scrollbar {
    /* width: 4px; */
    height: 13px;
}

.home-page .commands-section::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
}

.home-page .commands-section::-webkit-scrollbar-thumb {
    background: #67988E;
    border-radius: 20px;
}

.home-page .commands-section::-webkit-scrollbar-thumb:hover {
    background: #184438;
}

/******** End scrollbar css ********/


/*************************** Begin media ***************************/
@media screen and (max-width: 767px) {
    .absolute-bottom-right {
        position: relative;
        transform: translateX(0);
    }

    .form-group {
        margin: 10px 0;
    }
}


/************************************** Pagination Style **********************************************/
.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination .pagination-content {
    padding: 0;
    margin: 0;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid #b8bdc7;
}

.pagination .pagination-content span {
    list-style: none;
    background: #fff;
    padding: 10px 15px;
    display: inline-block;
    cursor: pointer;
}

@media (max-width: 556px) {
    .pagination .pagination-content span {
        padding: 10px 7px;
    }
}

.pagination .pagination-content span:hover {
    background: #e7e7e7;
}

.pagination .pagination-content span:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-right: 1px solid #dbd4d4;
}

.pagination .pagination-content span:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-left: 1px solid #dbd4d4;
}

.pagination .pagination-content span.page-number-active {
    background: #e7e7e7;
}
