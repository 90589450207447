
/*************************** Begin Navbar Section ***************************/
.navbar-section{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}
.navbar-section .header {
    position: relative;
    background: #fff;
    height: 130px;
    padding: 2px 14px;
    box-shadow: 0px -1px 30px -16px #184438;
}

.navbar-section .header a.acceuil-item{
    display: inline-block;
    text-decoration: none;
    color: #184438;
    background: #fff;
    cursor: pointer;
    padding: 3px 30px;
    border-radius: 32px;
    font-size: 25px;
    margin-left: 30px;
    font-weight: 600;
}
.navbar-section .header a.acceuil-item:hover{
    background: #184438;
    color: #fff;
}

.navbar-section .header svg{
    vertical-align: middle;
}

.navbar-section .header .header-content{ 
    position: absolute;
    top: 50%;
    right: 62px;
    transform: translate(0, -50%);
    display: flex;
    align-items: center
}
.navbar-section .header .header-content .alsalam-logo{
    display: inline-block;
}
.navbar-section .header .header-content .user-info {   
    text-align: right;
    display: inline-block;
}

.navbar-section .header .header-content .user-info h1 {
    font-size: 23px;
    display: inline-block;
    margin-right: 12px;
}
.navbar-section .header .header-content .user-info svg {
    vertical-align: sub;
}
.navbar-section .header .header-content .user-info span.small {
    font-size: 15px;
    color: #b7b2b2;
    margin-right: -8px;
}
.navbar-section .header .header-content .user-info ul.logout-box{   
    position: absolute;
    bottom: 37px;
    right: 0;
    transform: translate(0, 120%);
    opacity: 0;
    margin: 0;
    padding: 17px 23px;
    border-radius: 20px;
    width: 645px;
    background: #fff;
    box-shadow: 0 0 10px 4px rgb(0 0 0 / 10%);
    transition: .4s;
    visibility: hidden;
    text-align: left;
}
.navbar-section .header .header-content .user-info:hover ul.logout-box{  
    opacity: 1;
    transform: translate(0, 100%);
    visibility: visible;
}
.navbar-section .header .header-content .user-info ul.logout-box svg{
    vertical-align: middle;
}
.navbar-section .header .header-content .user-info ul.logout-box h2{
    display: inline-block;
    text-align: left;
    font-size: 30px;
    margin-left: 20px;
}
.navbar-section .header .header-content .user-info ul.logout-box h2 span{
    font-size: 26px;
}
.navbar-section .header .header-content .user-info ul.logout-box .logout-btn{
    text-align: right;
}
.navbar-section .header .header-content .user-info ul.logout-box .logout-btn p{
    background: #67988E;
    display: inline-block;
    color: #fff;
    padding: 5px 25px;
    border-radius: 25px;
    margin: 0;
    font-size: 21px;
    cursor: pointer;
}
.navbar-section .header .header-content .user-info ul.logout-box .logout-btn p p{
    padding: 0;
    padding-left: 10px;
}
/*************************** End Navbar Section ***************************/
